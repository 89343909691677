





































































import rService from '@/services/RequestService/RequestServiceSetup';

export default {
  name: 'DialogBreach',
  props: {},
  data() {
    return {
      item: '',
      loading: true,
      title: '',
      Domain: '',
      LeakBreachDate: '',
      description: '',
      DataClasses: [],
      BreachDate: '',
      AddedDate: '',
      ModifiedDate: '',
      LogoPath: '',
      PwnCount: 0,
      PwnCountDehashed: 0,
      EmailCount: 0,
      UserCount: 0,
      extra: {
        Category: '',
        Database: '',
        'Detected Hashing Algorithms': '',
        'Dump Date': '',
        DumpDateString: '',
        Entries: 0,
      },
    };
  },
  components: {
    'vs-popup': () => import('@/components/Popup/VSPopup.vue'),
  },
  methods: {
    updateListen(listen) {
      console.log('LISTEN', listen);
      if (listen === false) {
        this.$store.commit('breachPopup', null);
      }
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    },
    formatNumber(x: number) {
      if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      else return 0;
    },
    timeAgo(dateString: string) {
      return this.$moment(dateString).fromNow();
    },
    setup({ Name, Description, Domain, AddedDate, BreachDate, DataClasses, ModifiedDate, LogoPath, PwnCount, PwnCountDehashed, EmailCount, UserCount, extra, LeakBreachDate }) {
      this.title = Name;
      this.LogoPath = LogoPath;
      this.BreachDate = BreachDate;
      this.AddedDate = AddedDate;
      this.ModifiedDate = ModifiedDate;
      this.DataClasses = DataClasses;
      this.Domain = Domain || Name;
      this.description = Description;
      this.PwnCount = PwnCount;
      this.PwnCountDehashed = PwnCountDehashed;
      this.EmailCount = EmailCount;
      this.UserCount = UserCount;
      this.LeakBreachDate = LeakBreachDate;
      if (extra) {
        this.extra = {
          Category: extra.Category,
          Database: extra.Database,
          'Detected Hashing Algorithms': extra['Detected Hashing Algorithms'],
          'Dump Date': extra['Dump Date'],
          DumpDateString: extra.DumpDateString,
          Entries: extra.Entries,
        };
      } else {
        this.extra = {};
      }
    },
    async getBreach(breach: string) {
      this.loading = true;
      this.title = '';
      const model = `breaches/${encodeURIComponent(breach)}`;
      const res = await rService.get(model);
      this.setup(res);
      this.loading = false;
    },
  },
  computed: {
    popupActive: {
      get() {
        return this.$store.state.activeBreach !== '';
      },
      set(val) {
        if (!val) {
          this.$store.commit('breachPopup', '');
        }
      },
    },
  },
  watch: {
    popupActive(newVal) {
      if (newVal) {
        this.getBreach(this.$store.state.activeBreach);
      }
    },
  },
};
